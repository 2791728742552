<template>
    <div class="page">
        <div class="">
            <div class="container-login100 p-0">
                <div class="wrap-login100 p-7 p-sm-7">
                    <form method="POST" class="login100-form validate-form" :action="route_login">
                        <div class="text-center pb-4">
                            <a href="/"><img :src="'/assets/images/brand/logo-white.png'" height="60" class="header-brand-img" alt=""></a>
                        </div>
                        <div class="panel panel-primary">
                            <div class="tab-menu-heading">
                                <div class="tabs-menu1">
                                    <div>
                                        <p class="m-2">Coswick. Inspire </p>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body tabs-menu-body p-0 pt-6">
                                <div class="tab-content">
                                    <input type="hidden" name="_token" :value="csrf">
                                    <div class="tab-pane active" id="tab5">
                                        <div class="wrap-input100 validate-input input-group" data-bs-validate="Valid email is required: ex@abc.xyz">
                                            <a class="input-group-text bg-white text-muted" role="button">
                                                <i class="zmdi zmdi-email text-muted" aria-hidden="true"></i>
                                            </a>
                                            <input id="login" type="text" class="input100 border-start-0 form-control ms-0" name="login" required autocomplete="login" :placeholder="$t('auth.mail_or_login')" />
                                        </div>
                                        <div class="wrap-input100 validate-input input-group" id="Password-toggle">
                                            <a class="input-group-text bg-white text-muted" role="button">
                                                <i class="zmdi zmdi-eye text-muted" aria-hidden="true"></i>
                                            </a>
                                            <input id="password" type="password" name="password" required autocomplete="current-password" class="input100 border-start-0 form-control ms-0" :placeholder="$t('auth.password')" />
                                        </div>
<!--                                        <div class="text-end pt-2 pb-1">-->
<!--                                            <p><a :href="route_password_request" class="text-muted ms-1"> {{$t('auth.forgot_your_password')}}?</a></p>-->
<!--                                        </div>-->
                                        <div class="text-end pt-2 pb-1">
                                            <p><a :href="'/application-register'" class="text-muted ms-1">
                                                {{ whatLanguageShow('Заявка на регистрацию', 'Request for register') }}</a></p>
                                        </div>
                                        <div class="container-login100-form-btn mb-6">
                                            <button class="btn btn-danger w-100 waves-effect waves-light" type="submit">{{$t('auth.login_to_profile')}}</button>
                                        </div>
<!--                                        <label @click="$i18n.locale === 'ru' ? setLanguage('en') : $i18n.locale === 'en' ? setLanguage('ru') : ''" role="button" class="login-social-icon"><span>{{$i18n.locale === 'ru' ? 'English language' : $i18n.locale === 'en' ? 'Русский язык' : ''}}</span></label>-->
<!--                                        <label class="login-social-icon lh-1"><a href="https://www.coswick.com/" style="color: darkgrey !important;" target="_blank">coswick.com</a></label>-->
                                        <div class="text-center">
                                            <p class="text-white-50">© {{new Date().getFullYear()}} {{$t('home.company_coswick')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoginComponent",
    props:['route_login','route_password_request','csrf'],
    created() {
        this.setLanguage('en')
    },
    methods:{
        setLanguage(val){
            this.$i18n.locale = val
            localStorage.setItem('lang',this.$i18n.locale)
        }
    },
}
</script>

<style scoped>

</style>
