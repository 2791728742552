import { createApp } from "vue/dist/vue.esm-bundler";
import Login from "./components/auth/LoginComponent.vue";
import ChangePassword from "./components/auth/ChangePasswordComponent.vue";

// Mixins
import Language from "./mixins/Language.vue";

// i18n
import { languages } from "./i18n";
import { defaultLocale } from "./i18n";
import {createI18n,useI18n} from 'vue-i18n'

const localeStorageLang = localStorage.getItem('lang')
const messages = Object.assign(languages)
const i18n = createI18n({
    legacy:false,
    locale: localeStorageLang || 'en',//defaultLocale,
    fallbackLocale:'ru',
    messages
})

const auth = createApp({
    components: {
        Login,
        ChangePassword
    },
})

auth.mixin(Language)
auth.use(i18n).mount('#auth');
